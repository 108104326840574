import { Container } from './styles'
import cnb from '../../assets/cnb-les-avocats.png'
import doctrine from '../../assets/logo_navbar.png'
import ordre from '../../assets/Logo-ORDRE_longV2.png'
import expert from '../../assets/cropped-mon-expert-du-droit.png'
// import discordIcon from '../../assets/discord.png'

export function Footer() {
  return (
    <Container className="footer">
      <a href="https://www.pascalefabre-avocat-aix.com" className="logo">
          <span>{"Maitre "}</span>
          <span>⚖️</span>
          <span>{" Fabre"}</span>
      </a>
      <div>
        {/* <p>
          L'apparence du site a été imaginé par https://vinayaksingh.in
        </p> */}
      </div>
      <div className="social-media">
      <a
        href="https://consultation.avocat.fr/avocat-aix-en-provence/pascale-fabre-38963.html"
        target="_blank"
        rel="noreferrer"
      >
        <img src={cnb} alt="CNB" />
      </a>
        <a
          href="https://www.doctrine.fr/p/avocat/L06ADC60399107814EAFC"
          target="_blank"
          rel="noreferrer"
        >
          <img src={doctrine} alt="doctrine" />
        </a>
        <a
          href="https://www.barreauaix.com/listing/pascale-fabre/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={ordre} alt="ordre" />
        </a>
        <a
          href="https://monexpertdudroit.com/avocat/provence-alpes-cote-dazur/aix-en-provence/pascale-fabre/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={expert} alt="expert" />
        </a>
      </div>
    </Container>
  )
}
