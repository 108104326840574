import { Container } from './styles'
import { BrowserRouter as Router } from 'react-router-dom'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import reactIcon from '../../assets/balance.svg'
import { useState, useEffect } from 'react'
import Resume from '../../assets/Vinayak_Kumar_Singh_Resume.pdf'
export function Header() {
  const [isActive, setActive] = useState(false)

  useEffect(() => {
    // Appliquer la classe "light" par défaut à <html>
    let html = document.getElementsByTagName("html")[0];
    html.classList.add("light");
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois, au montage.


  function toggleTheme() {
    let html = document.getElementsByTagName('html')[0]
    html.classList.toggle('light')
  }
  function closeMenu() {
    setActive(false)
  }
  return (
    <Container className="header-fixed">
      <Router>
        <HashLink smooth to="#home" className="logo">
          <span>{"Maitre "}</span>
          <span>⚖️</span>
          <span>{" Fabre"}</span>
        </HashLink>
        <input
          onChange={toggleTheme}
          className="container_toggle"
          type="checkbox"
          id="switch"
          name="mode"
        />
        <label htmlFor="switch">Toggle</label>
        <nav className={isActive ? 'active' : ''}>
          <NavHashLink smooth to="#home" onClick={closeMenu}>
            Accueil
          </NavHashLink>
          <NavHashLink smooth to="#about" onClick={closeMenu}>
            A propos de moi
          </NavHashLink>
          <NavHashLink smooth to="#project" onClick={closeMenu}>
            Domaines de compétences
          </NavHashLink>
          <NavHashLink smooth to="#contact" onClick={closeMenu}>
            Contact
          </NavHashLink>
          {/* <a href={Resume} download className="button">
            Resume
          </a> */}
        </nav>
        <div
          aria-expanded={isActive ? 'true' : 'false'}
          aria-haspopup="true"
          aria-label={isActive ? 'Fechar menu' : 'Abrir menu'}
          className={isActive ? 'menu active' : 'menu'}
          onClick={() => {
            setActive(!isActive)
          }}
        ></div>
      </Router>
    </Container>
  )
}
