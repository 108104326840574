import { BrowserRouter } from "react-router-dom"
import { Container } from "./styles"
import ScrollAnimation from "react-animate-on-scroll"
import Illustration from "../../assets/lawyer.png"
import { NavHashLink } from "react-router-hash-link"
import cnb from '../../assets/cnb-les-avocats.png'
import doctrine from '../../assets/logo_navbar.png'
import ordre from '../../assets/Logo-ORDRE_longV2.png'
import Hello from '../../assets/Hello.gif'
import expert from '../../assets/cropped-mon-expert-du-droit.png'
export function Hero() {
  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp">
          <p>Bonjour <img src={Hello} alt="Hello" width="20px"/>, Je suis</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Pascale Fabre</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3> Avocate à la cour</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">Plus de 30 années d'expérience</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <BrowserRouter>
            <NavHashLink smooth to="#contact" className="button">Contact</NavHashLink>
          </BrowserRouter>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
      <div className="social-media">
      <a
        href="https://consultation.avocat.fr/avocat-aix-en-provence/pascale-fabre-38963.html"
        target="_blank"
        rel="noreferrer"
      >
        <img src={cnb} alt="CNB" />
      </a>
        <a
          href="https://www.doctrine.fr/p/avocat/L06ADC60399107814EAFC"
          target="_blank"
          rel="noreferrer"
        >
          <img src={doctrine} alt="doctrine" />
        </a>
        <a
          href="https://www.barreauaix.com/listing/pascale-fabre/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={ordre} alt="ordre" />
        </a>
        <a
          href="https://monexpertdudroit.com/avocat/provence-alpes-cote-dazur/aix-en-provence/pascale-fabre/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={expert} alt="expert" />
        </a>
        </div>
        </ScrollAnimation>
      </div>
      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
        <img src={Illustration} alt="Illustration"/>
        </ScrollAnimation>
      </div>
    </Container>
  )
}