import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg"
import gpsIcon from "../../assets/gps-svgrepo-com.svg"
import { Form } from "../Form/Form";


export function Contact(){

  return(
    <Container id="contact">
      <header>
        <h2>Contact</h2>
        <p>Besoin d'une consultation ? </p>
        <p>Prenez contact avec moi</p>
      </header>
      <div className="contacts">
        <div>
        <a href="mailto:contact@pascalefabre-avocat-aix.com"><img src={emailIcon} alt="Email" /></a> 
          <a href="mailto:contact@pascalefabre-avocat-aix.com">contact@pascalefabre-avocat-aix.com</a>
        </div>
        <div>
        <a href="tel:+33442385055"><img src={phoneIcon} alt="Phone No" /></a>
          <a href="tel:+33442385055">04 42 38 50 55 </a>
        </div>  
        <div>
        <a href="https://www.google.com/maps/place/7+Rue+Emeric+David,+13100+Aix-en-Provence/@43.5283675,5.4518676,20.5z/data=!4m6!3m5!1s0x12c98dbe73a6379d:0x8424b702ef5ab63e!8m2!3d43.5283452!4d5.452023!16s%2Fg%2F11b8v4l266?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D"><img src={gpsIcon} alt="Gps No" /></a>
          <a href="https://www.google.com/maps/place/7+Rue+Emeric+David,+13100+Aix-en-Provence/@43.5283675,5.4518676,20.5z/data=!4m6!3m5!1s0x12c98dbe73a6379d:0x8424b702ef5ab63e!8m2!3d43.5283452!4d5.452023!16s%2Fg%2F11b8v4l266?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D">7 rue Emeric DAVID,13100, Aix-en-Provence </a>
        </div> 
      </div> 
      {/* <Form></Form> */}
    </Container>
  )
}